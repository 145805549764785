import React from "react";
import Carousel from "react-multi-carousel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";

import Container from "../../Common/Container";
import importAll from "../../../lib/utils/image";
import TestimonialCard from "../../Common/TestimonialCard/TestimonialCard";

import "react-multi-carousel/lib/styles.css";

const Testimonials = () => {
  const { t } = useTranslation("testimonials");
  const blocks = t("testimonials.blocks", { returnObjects: true });
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1200, min: 900 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 900, min: 600 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  const allImages = importAll(
    require.context(
      "../../../public/static/images/home/testimonials/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  return (
    <Box marginY={4}>
      <Container paddingY={"0 !important"}>
        <Box marginBottom={4}>
          <Box
            component={Typography}
            fontWeight={700}
            variant={"h3"}
            gutterBottom
            align={"center"}
          >
            {`${t("testimonials.title1")} ${t("testimonials.title2")}`}
          </Box>
          <Typography
            variant={"h6"}
            component={"p"}
            color={"textSecondary"}
            align={"center"}
            sx={{ marginX: 10 }}
          >
            {t("testimonials.subtitle")}
          </Typography>
        </Box>
      </Container>
      <Box>
        <Carousel
          showDots={true}
          arrows={false}
          autoPlay
          autoPlaySpeed={4000}
          infinite
          pauseOnHover
          responsive={responsive}
        >
          {blocks.map((item) => (
            <TestimonialCard
              fullName={item.full_name}
              content={item.content}
              job={item.job}
              image={allImages[item.image]}
              key={item.full_name}
            />
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default Testimonials;
