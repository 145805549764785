import React from "react";
import NextLink from "next/link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { useTranslation } from "next-i18next";

import importAll from "../../../lib/utils/image";
import CloudinaryImage from "../../Common/CloudinaryImage";

const FitsYourNeeds = ({ title, blocks }) => {
  const { t } = useTranslation("common");
  const allImages = importAll(
    require.context(
      "../../../public/static/images/home/fits_your_needs/",
      false,
      /\.(png|jpe?g|svg|webp)$/
    )
  );

  return (
    <Box marginTop={4} marginBottom={6}>
      <Box marginBottom={8}>
        <Typography
          component="h3"
          fontWeight={700}
          variant="h4"
          align="center"
          gutterBottom
        >
          {title}
        </Typography>
      </Box>
      <Grid container spacing={6}>
        {blocks.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box display={"block"} width={"100%"} height={"100%"}>
              <Box
                component={Card}
                width={"100%"}
                height={"100%"}
                borderRadius={3}
                data-aos="fade-up"
              >
                <CardMedia title={item.title}>
                  <CloudinaryImage
                    src={allImages[`bloc_${i + 1}.webp`]}
                    alt={item.title}
                    height={340}
                    width={380}
                    layout="responsive"
                    placeholder="blur"
                  />
                </CardMedia>
                <Box component={CardContent}>
                  <Typography
                    component={"h4"}
                    variant={"h6"}
                    gutterBottom
                    fontWeight={500}
                    align={"left"}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    align={"left"}
                    variant={"body1"}
                    color="textSecondary"
                  >
                    {item.subtitle}
                  </Typography>
                </Box>
                {item.link && (
                  <CardActions>
                    <NextLink href={item.link} passHref>
                      <Button
                        size="large"
                        endIcon={
                          <svg
                            width={16}
                            height={16}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </svg>
                        }
                      >
                        {t("common.learn_more")}
                      </Button>
                    </NextLink>
                  </CardActions>
                )}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FitsYourNeeds;
