import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import getDocBySlug from "../services/getDocBySlug.mjs";
import MetaTags from "../components/Common/MetaTags";
import Container from "../components/Common/Container";
import Clients from "../components/Common/Clients";
import FitsYourNeeds from "../components/Home/FitsYourNeeds";
import Hero from "../components/Home/Hero";
import Highlight from "../components/Home/Highlight";
import WhyFluicity from "../components/Home/WhyFluicity";
import Testimonials from "../components/Home/Testimonials";
import Newsletter from "../components/Home/Newsletter";

export default function Home({
  hero,
  clients,
  fits_your_needs,
  highlight,
  why_fluicity,
  newsletter,
}) {
  const theme = useTheme();

  return (
    <>
      <MetaTags scope="home" />

      <Box bgcolor={theme.palette.alternate.main}>
        <Container>
          <Hero {...hero} />
        </Container>
      </Box>
      <Clients {...clients} />
      <Box bgcolor={theme.palette.alternate.main}>
        <Container>
          <FitsYourNeeds {...fits_your_needs} />
        </Container>
      </Box>
      <Container>
        <Highlight {...highlight} />
      </Container>
      <Container>
        <WhyFluicity {...why_fluicity} />
      </Container>
      <Box bgcolor={theme.palette.alternate.main}>
        <Container paddingX={"0 !important"} maxWidth={"100%"}>
          <Testimonials />
        </Container>
      </Box>
      <Container>
        <Newsletter {...newsletter} />
      </Container>
    </>
  );
}

export async function getStaticProps({ locale }) {
  const { meta } = getDocBySlug("home", locale);

  return {
    props: {
      ...meta,
      ...(await serverSideTranslations(locale, [
        "common",
        "home",
        "testimonials",
      ])),
    },
  };
}
