import React from "react";

const PipeDriveForm = ({ height = 750 }) => {
  return (
    <div className="pipedriveWebForms">
      <iframe
        src="https://info.efalia.com/l/1074742/2024-10-10/44kmp49"
        width="100%"
        height="1300"
        type="text/html"
        frameBorder="0"
        allowtransparency="true"
        style={{ border: "none" }}
        seamless="seamless"
      />
    </div>
  );
};

export default PipeDriveForm;
