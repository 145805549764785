import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";
import PipeDriveForm from "../../Common/PipedriveForm/PipedriveForm";

const Newsletter = ({ title, subtitle }) => {
  const { t } = useTranslation("home");

  return (
    <Box>
      <Box marginBottom={4}>
        <Box
          component={Typography}
          fontWeight={700}
          variant={"h3"}
          gutterBottom
          align={"center"}
        >
          {title}
        </Box>
        <Typography
          align={"center"}
          color="textSecondary"
          variant={"h6"}
          component="h4"
          sx={{ marginX: 8 }}
        >
          {subtitle}
        </Typography>
      </Box>
      <Box maxWidth={400} margin={"0 auto"}>
        <PipeDriveForm formId={t("newsletter.newsletter_form_id")} height={250}/>
      </Box>
    </Box>
  );
};

export default Newsletter;
