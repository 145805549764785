import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import bloc1Image from "../../../public/static/images/home/highlight/highlight_1.png";
import bloc2Image from "../../../public/static/images/home/highlight/highlight_2.png";
import CloudinaryImage from "../../Common/CloudinaryImage";

const Highlight = ({ bloc1, bloc2 }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box marginY={4}>
      <Box component={Grid} container sx={{ marginBottom: 10 }}>
        <Grid item xs={12} md={6} order={{ xs: 2, sm: 2, md: 1 }}>
          <Box width={1} height="100%" display="flex" alignItems="center">
            <Box data-aos={isMd ? "fade-right" : "fade-up"}>
              <Typography
                component="h3"
                fontWeight={700}
                variant="h4"
                gutterBottom
              >
                {bloc1.title}
              </Typography>
              <Typography
                variant={"h6"}
                component={"p"}
                color={"textSecondary"}
              >
                {bloc1.content}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, sm: 1, md: 2 }}>
          <Box
            width={1}
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              width={219}
              height={123}
              data-aos={isMd ? "fade-left" : "fade-up"}
              mb={3}
            >
              <CloudinaryImage
                src={bloc1Image}
                alt={bloc1.title}
                width={219}
                height={123}
                placeholder="blur"
                layout="fixed"
              />
            </Box>
          </Box>
        </Grid>
      </Box>

      <Grid container>
        <Grid item xs={12} md={6}>
          <Box
            width={1}
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              width={221}
              height={149}
              data-aos={isMd ? "fade-right" : "fade-up"}
              mb={3}
            >
              <CloudinaryImage
                src={bloc2Image}
                alt={bloc2.title}
                width={221}
                height={149}
                placeholder="blur"
                layout="fixed"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box width={1} height="100%" display="flex" alignItems="center">
            <Box data-aos={isMd ? "fade-left" : "fade-up"}>
              <Typography
                component="h3"
                fontWeight={700}
                variant="h4"
                gutterBottom
              >
                {bloc2.title}
              </Typography>
              <Typography
                variant={"h6"}
                component={"p"}
                color={"textSecondary"}
              >
                {bloc2.content}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Highlight;
