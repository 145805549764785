import React from "react";
import NextLink from "next/link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTranslation } from "next-i18next";

import importAll from "../../../lib/utils/image";
import CloudinaryImage from "../../Common/CloudinaryImage";

const WhyFluicity = ({ title, subtitle, blocks }) => {
  const { t } = useTranslation("home");
  const allImages = importAll(
    require.context(
      "../../../public/static/images/home/why_fluicity/",
      false,
      /\.(png|jpe?g|svg|webp)$/
    )
  );

  return (
    <Box marginY={4}>
      <Box marginBottom={4}>
        <Box
          component={Typography}
          fontWeight={700}
          variant={"h3"}
          align={"center"}
          gutterBottom
        >
          {title}
        </Box>
        <Typography
          variant={"h6"}
          component={"p"}
          color={"textSecondary"}
          align={"center"}
        >
          {subtitle}
        </Typography>
      </Box>
      <Grid container spacing={8}>
        {blocks.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box width={1} height={"100%"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Box width={150} height={100} marginBottom={2}>
                  <CloudinaryImage
                    src={allImages[`bloc_${i + 1}.webp`]}
                    alt={item.title}
                    width={150}
                    height={100}
                    placeholder="blur"
                    layout="fixed"
                  />
                </Box>
                <Typography
                  variant={"h6"}
                  component="h4"
                  gutterBottom
                  fontWeight={500}
                  align={"center"}
                >
                  {item.title}
                </Typography>
                <Typography align={"center"} color="textSecondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box marginTop={5} align="center">
        <NextLink href="/about" passHref>
          <Button
            variant="outlined"
            color="primary"
            component="a"
            size="large"
            endIcon={
              <svg
                width={16}
                height={16}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            }
          >
            {t("why_fluicity.cta")}
          </Button>
        </NextLink>
      </Box>
    </Box>
  );
};

export default WhyFluicity;
