import React from "react";
import NextLink from "next/link";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import homeBanner from "../../../public/static/images/home/Home-Banner.webp";
import CloudinaryImage from "../../Common/CloudinaryImage";

const Hero = ({
  slogan1,
  slogan2,
  slogan3,
  subSlogan,
  ctaPrimaryLabel,
  ctaSecondaryLabel,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems="center" xs={12} md={6}>
        <Box>
          <Box marginBottom={2}>
            <Typography
              variant="h3"
              component="h1"
              color="textPrimary"
              sx={{
                fontWeight: 700,
                marginBottom: 4,
              }}
            >
              {slogan1}
              <Typography color="primary" component="span" variant="inherit">
                {" "}
                {slogan2}{" "}
              </Typography>
              {slogan3}
            </Typography>
          </Box>
          <Box marginBottom={10}>
            <Typography
              variant="h6"
              component="h2"
              color="textSecondary"
              sx={{ fontWeight: 400 }}
            >
              {subSlogan}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "stretched", sm: "flex-start" }}
          >
            <NextLink href="/demo" passHref>
              <Button
                component="a"
                variant="contained"
                color="primary"
                size="large"
                fullWidth={isMd ? false : true}
              >
                {ctaPrimaryLabel}
              </Button>
            </NextLink>
            <Box
              marginTop={{ xs: 2, sm: 0 }}
              marginLeft={{ sm: 2 }}
              width={{ xs: "100%", md: "auto" }}
            >
              <NextLink href="/plateforme-consultation-citoyenne" passHref>
                <Button
                  component="a"
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth={isMd ? false : true}
                >
                  {ctaSecondaryLabel}
                </Button>
              </NextLink>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Box width={586} height={690}>
            <CloudinaryImage
              src={homeBanner}
              width={586}
              height={690}
              alt="Fluicity illustration"
              priority
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Hero;
