import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Carousel from "react-multi-carousel";

import importAll from "../../../lib/utils/image";
import CloudinaryImage from "../CloudinaryImage";

import "react-multi-carousel/lib/styles.css";

const Clients = ({ title = "" }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 1200, min: 900 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 900, min: 600 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
    },
  };

  const allImages = importAll(
    require.context(
      "../../../public/static/images/home/clients/",
      false,
      /\.(png|jpe?g|svg|webp)$/
    )
  );

  return (
    <Box marginTop={10} marginBottom={5}>
      <Typography
        variant={"h6"}
        component={"p"}
        color={"textSecondary"}
        align={"center"}
        sx={{ paddingX: 2 }}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <Box sx={{ minHeight: 213 }}>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay
          autoPlaySpeed={5}
          customTransition="all 5s linear"
          transitionDuration={5000}
          infinite
          pauseOnHover
          responsive={responsive}
        >
          {Object.entries(allImages).map((logo, i) => (
            <Box component={Card} boxShadow="none" key={i}>
              <Box
                component={CardContent}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                padding={{ xs: 2, sm: 4, md: 6 }}
                sx={{
                  "&:last-child": {
                    paddingBottom: { xs: 2, sm: 4, md: 6 },
                  },
                }}
              >
                <CloudinaryImage
                  src={logo[1]}
                  alt={logo[0]}
                  placeholder="blur"
                />
              </Box>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default Clients;
